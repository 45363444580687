import { configureStore } from "@reduxjs/toolkit";
import {
    persistStore, persistReducer,
    FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import userSlice from "../slices/userSlice";
import deviceSlice from "../slices/deviceSlice";
import paperSlice from "../slices/paperSlice";

// create user reducer
const userReducer = userSlice.reducer;

// create device reducer using persistReducer to hold its states against page refresh
const deviceReducer = deviceSlice.reducer;

const persistDeviceConfig = {
    key: 'device',
    storage
}

const persistDeviceReducer = persistReducer(persistDeviceConfig, deviceReducer);

// create paper reducer
const paperReducer = paperSlice.reducer;

const store = configureStore({
    reducer: {
        userInfo: userReducer,
        deviceInfo: persistDeviceReducer,
        paperInfo: paperReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    })
});

const persistor = persistStore(store);

export { store, persistor };
