import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';

export class ConnectedThird extends Component {
    render() {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Username</th>
                        <th>GPU Selected (this row will live through page refresh supported by <code>redux-persist</code>)</th>
                        <th>Comments</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">1</th>
                        <td>{this.props.login?.user.username}</td>
                        <td>{this.props.product?.GPU}</td>
                        <td>{this.props.paper?.Content}</td>
                    </tr>
                </tbody>
            </Table>
        );
    }
}

const mapStateToProps = state => {
    return { login: state.userInfo, product: state.deviceInfo, paper: state.paperInfo }
};

const Third = connect(mapStateToProps)(ConnectedThird);

export default Third;
