import { createSlice } from "@reduxjs/toolkit";

const deviceSlice = createSlice({
    name: "deviceInfo",
    initialState: { GPU: '', Brand: '', Version: '' },
    reducers: {
        selectGPU: (state, action) => {
            state.GPU = action.payload;
        },
        selectBrand: (state, action) => {
            state.Brand = action.payload;
        },
        selectVersion: (state, action) => {
            state.Version = action.payload;
        },
        resetDeviceInfo: (state) => {
            state.GPU = '';
            state.Brand = '';
            state.Version = '';
        }
    }
});

export const { selectGPU, selectBrand, selectVersion, resetDeviceInfo } = deviceSlice.actions;

export default deviceSlice;
