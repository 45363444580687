import { createSlice } from "@reduxjs/toolkit";

const paperSlice = createSlice({
    name: "paperInfo",
    initialState: { Title: '', Content: '', Version: '' },
    reducers: {
        setTitle: (state, action) => {
            state.Title = action.payload;
        },
        setContent: (state, action) => {
            state.Content = action.payload;
        },
        setVersion: (state, action) => {
            state.Version = action.payload;
        },
        resetPaper: (state) => {
            state.Title = '';
            state.Content = '';
            state.Version = '';
        }
    }
});

export const { setTitle, setContent, setVersion, resetPaper } = paperSlice.actions;

export default paperSlice;
