import React, { Component } from "react";
import logo from "./img/logo.png";
import "./Home.css";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

export default class Home extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} alt="logo" />
          <h1>RAR Password Generator</h1>
          <h5>RARPG压缩文件密码启发式恢复软件<sup>&copy;</sup></h5>
          <Button color="primary" size="lg" tag={Link} to="/download/">
            最新版下载
          </Button>
        </header>
      </div>
    );
  }
}
