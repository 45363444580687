/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../../services/UserLogin/userService";

export const userLogin = createAsyncThunk(
  "userInfo/userLoginStatus",
  async (thunkAPI) => {
    // const response = await axios({ method: 'get', url: `${process.env.REACT_APP_API_ROOT}api/login/getLoginInfo` });
    var loginService = new userService();
    const response = await loginService.login();
    return response.data;
  }
);

const userSlice = createSlice({
  name: "userInfo",
  initialState: { user: {}, loading: true },
  reducers: {
    logoutUser: (state) => {
      state.user = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state, action) => {
      state.loading = true;
      return state;
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      return state;
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.loading = false;
      state.user = { username: "Error" };
      return state;
    });
  },
});

export default userSlice;
