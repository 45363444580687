import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { selectGPU } from '../../redux/slices/deviceSlice';
import { setContent } from '../../redux/slices/paperSlice';

export class ConnectedSecond extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userSelection: '',
            textArea: 'Input your comments here!!!'
        }
    }

    componentDidMount() {
        let GPU = this.props.product?.GPU;
        if (GPU)
            this.setState({
                userSelection: GPU
            });
    }

    handleInputChange = (e) => {
        let text = e.target.value;
        this.setState({
            textArea: text
        });
        let username = this.props.user?.username;
        if (this.props.writeComment)
            this.props.writeComment(`${username} commented: ${text}`);
    }

    handleSelectChange = (e) => {
        let selected = e.target.value;
        this.setState({
            userSelection: selected
        });
        if (this.props.selectGPU)
            this.props.selectGPU(selected);
    }

    render() {
        return (
            <Form>
                <FormGroup>
                    <Label for="exampleEmail">Email</Label>
                    <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                </FormGroup>
                <FormGroup>
                    <Label for="examplePassword">Password</Label>
                    <Input type="password" name="password" id="examplePassword" placeholder="password placeholder" />
                </FormGroup>
                <FormGroup>
                    <Label for="exampleSelect">Select GPU</Label>
                    <Input type="select" name="select" id="exampleSelect"
                        value={this.state.userSelection} onChange={this.handleSelectChange}>
                        <option>MSI RTX 2080 Super</option>
                        <option>Gigabyte RTX 2080 Super</option>
                        <option>MSI RTX 3080 Suprim X</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="exampleText">Please input your comments: </Label>
                    <Input type="textarea" name="text" id="exampleText"
                        value={this.state.textArea} onChange={this.handleInputChange} />
                </FormGroup>
                <FormGroup>
                    <Label for="exampleFile">File</Label>
                    <Input type="file" name="file" id="exampleFile" />
                    <FormText color="muted">
                        This is some placeholder block-level help text for the above input.
                        It is a bit lighter and easily wraps to a new line.
                    </FormText>
                </FormGroup>
                <FormGroup tag="fieldset">
                    <legend>Radio Buttons</legend>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="radio1" />{' '}
                            Option one is this and that—be sure to include why it is great
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="radio1" />{' '}
                            Option two can be something else and selecting it will deselect option one
                        </Label>
                    </FormGroup>
                    <FormGroup check disabled>
                        <Label check>
                            <Input type="radio" name="radio1" disabled />{' '}
                            Option three is disabled
                        </Label>
                    </FormGroup>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input type="checkbox" />{' '}
                        Check me out
                    </Label>
                </FormGroup>
                <Button tag={Link} to="/third/">Submit</Button>
            </Form>
        );
    }
}

const mapStateToProps = state => {
    return { user: state.userInfo?.user, product: state.deviceInfo }
};

const mapDispatchToProps = dispatch => {
    return {
        selectGPU: (GPU) => dispatch(selectGPU(GPU)),
        writeComment: (comment) => dispatch(setContent(comment))
    };
}

const Second = connect(mapStateToProps, mapDispatchToProps)(ConnectedSecond);

export default Second;
