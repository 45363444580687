import { Row, Col, Card, CardTitle, CardText } from "reactstrap";
import TradeCalculator from "./TradeCalculator";

export default function Trade() {
    return (
        <div style={{ marginTop: "8px", marginBottom: "8px" }}>
            <Row>
                <Col sm="6" style={{ marginTop: "8px", marginBottom: "8px" }}>
                    <Card body>
                        <CardTitle tag="h5">
                            Buy
                        </CardTitle>
                        <CardText>
                            Calculate the average after Buy
                        </CardText>
                        <TradeCalculator action="Buy" color="danger" />
                    </Card>
                </Col>
                <Col sm="6" style={{ marginTop: "8px", marginBottom: "8px" }}>
                    <Card body>
                        <CardTitle tag="h5">
                            Sell
                        </CardTitle>
                        <CardText>
                            Calculate the average after Sell
                        </CardText>
                        <TradeCalculator action="Sell" color="success" />
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
