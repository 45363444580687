import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, CardText, CardTitle } from "reactstrap";

export default class Help extends Component {
  render() {
    return (
      <div>
        <Card className="my-2" color="info" inverse>
          <CardHeader>帮助文档</CardHeader>
          <CardBody>
            <CardTitle tag="h5">RAR Password Generator</CardTitle>
            <CardText>软件使用说明</CardText>
          </CardBody>
        </Card>

        <div className="form-group">
          <div className="row">
            <div
              className="col-sm"
              style={{ marginTop: "6px", marginBottom: "6px" }}
            >
              <div className="list-group">
                <div className="list-group-item list-group-item-info">
                  基础教程
                </div>
                <a
                  href="#overview"
                  className="list-group-item list-group-item-action"
                >
                  概览
                </a>
                <a
                  href="#basics"
                  className="list-group-item list-group-item-action"
                >
                  基本使用教程
                </a>
                <a
                  href="#progress"
                  className="list-group-item list-group-item-action"
                >
                  扫描进度保存与加载
                </a>
                <Link
                  to="/download/"
                  className="list-group-item list-group-item-action"
                >
                  软件更新
                </Link>
              </div>
            </div>
            <div
              className="col-sm"
              style={{ marginTop: "6px", marginBottom: "6px" }}
            >
              <div className="list-group">
                <div className="list-group-item list-group-item-success">
                  进阶教程
                </div>
                <a
                  href="#dictionary"
                  className="list-group-item list-group-item-action"
                >
                  密码字典使用说明<b style={{ color: "red" }}>（重要）</b>
                </a>
                <a
                  href="#password"
                  className="list-group-item list-group-item-action"
                >
                  密码选项使用说明
                </a>
                <a
                  href="#threading"
                  className="list-group-item list-group-item-action"
                >
                  线程数使用说明
                </a>
                <a
                  href="#others"
                  className="list-group-item list-group-item-action"
                >
                  其他使用说明
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="col">
            <h3 id="overview">概览</h3>
            <p>
              本软件是一款专业扫描RAR压缩包密码的工具，旨在帮助您找回丢失或者忘记的RAR压缩包的密码。支持自定义密码字典功能，支持密码前缀后缀扫描功能，支持扫描进度保存功能，支持符号密码扫描（需配置密码字典），支持汉字密码扫描（需配置密码字典），支持多线程。
            </p>
            <p>
              支持扫描RAR与ZIP格式的压缩文件密码，新增支持最新 RAR 5.0
              文档格式。
            </p>
            <p>
              自定义密码字典是本软件的一项重要功能，请务必认真阅读
              <a href="#dictionary">密码字典使用说明</a>。
            </p>
            <p>
              <a href="#index">返回目录</a>
            </p>
          </div>
        </div>

        <div className="form-group">
          <div className="col">
            <h3 id="basics">基本使用教程</h3>
            <ol>
              <li>
                <p>运行软件，单击打开RAR，选择需要扫描的RAR文件。</p>
              </li>
              <li>
                <p>单击开始，即可开始根据字典生成密码并扫描目标RAR文件。</p>
              </li>
              <li>
                <p>
                  开始扫描，软件会定时保存扫描进度以免因电脑断电或系统异常造成的进度丢失。
                </p>
              </li>
              <li>
                <p>
                  如果在扫描途中找到了正确的密码，软件会停止扫描并显示密码。
                </p>
              </li>
            </ol>
            <p>
              <a href="#index">返回目录</a>
            </p>
          </div>
        </div>

        <div className="form-group">
          <div className="col">
            <h3 id="progress">扫描进度保存与加载</h3>
            <p>
              软件支持扫描进度保存与加载功能，如需中途退出，可以暂停扫描，并且下次加载本次扫描进度以继续。
            </p>
            <h5>进度保存功能</h5>
            <ol>
              <li>
                <p>开始扫描后，如需保存进度，请单击“暂停”，以暂停扫描进程。</p>
              </li>
              <li>
                <p>
                  暂停后，可以单击“保存进度”来选择保存扫描进度。扫描进度为
                  json/xml 格式的文件，请选择好路径妥善保存。
                </p>
              </li>
              <li>
                <p>保存完成后，即可退出软件，下次运行即可继续本次扫描。</p>
              </li>
            </ol>
            <h5>进度加载功能</h5>
            <ol>
              <li>
                <p>
                  软件启动后，如需加载进度，请先加载上次扫描的RAR文件。加载完成后，单击“加载进度”。找到上次保存的进度文件加载即可。
                </p>
              </li>
              <li>
                <p>加载进度成功之后，软件会自动恢复到上次扫描中断的位置。</p>
              </li>
              <li>
                <p>然后单击“开始”即可继续接着上次扫描。</p>
              </li>
            </ol>
            <p>
              <a href="#index">返回目录</a>
            </p>
          </div>
        </div>

        <div className="form-group">
          <div className="col">
            <h3 id="dictionary">
              密码字典使用说明<b style={{ color: "red" }}>（重要）</b>
            </h3>
            <p>
              <a
                href="https://baike.sogou.com/v7609453.htm?fromTitle=%E5%AD%97%E5%85%B8%E5%AF%86%E7%A0%81"
                target="_blank"
                rel="noopener noreferrer"
                title="单击打开词条百科"
              >
                密码字典
              </a>
              文件（Dictionary.txt）位于软件的安装目录，软件开始运行之后，会根据此字典生成密码组合对加密RAR压缩包进行扫描。默认字典为大小写英文字母与数字0-9，请务阅读此字典教程并根据您的需要配置您的字典。
            </p>
            <p>
              请确保您使用的字典文件的编码方式为：Unicode，以支持多语言密码或特殊符号密码。
              字典文件中，每一行代表一个密码元素，运行时，本软件将通过密码元素的排列组合，生成密码并进行扫描。
            </p>
            <p>例如，Dictionary.txt 的内容为（注意换行）：</p>
            <p>AGP</p>
            <p>ha</p>
            <p>wow</p>
            <p>lol</p>
            <p>GBA</p>
            <p>则，软件将会以上述基本内容生成密码组合。如下所示：</p>
            <p>haAGP（元素 ha 与元素 AGP 组合）</p>
            <p>GBAlol</p>
            <p>AGPGBA</p>
            <p>lolwow</p>
            <p>GBAwow</p>
            <p>wowAGP</p>
            <p>wowlolGBA</p>
            <p>
              hawowAGPlolGBA（元素 ha、元素 AGP、元素 wow、元素 lol 和元素 GBA
              组合）
            </p>
            <p>
              <a href="#index">返回目录</a>
            </p>
          </div>
        </div>

        <div className="form-group">
          <div className="col">
            <h3 id="password">密码选项使用说明</h3>
            <p>
              若已知密码前面几位或后面几位的字符（未知则留空），则在密码前后缀处输入已知字符。
              例如：前缀处输入 www. ，后缀处输入 .com ，则可以生成类似
              www.Password.com 格式的密码，即网址类型的密码。
            </p>
            <p>
              密码最小长度说明：此最小长度仅限于新开始一个RAR任务时使用，运行途中不支持修改。
              本实例中的长度，不是密码字符串的长度，而是构成密码的密码元素的个数。
              『关于密码元素的说明：密码元素是一个整体，它是用来生成密码的基础字符串（长度在密码最大长度以下即可）。密码元素保存在字典文件中，一个密码元素占用一行。』
              如果您的字典元素长度都是1，那么您限制的密码最小长度就是密码字符串的最小长度。
              所以，您限制的密码最小长度可能会大于密码字符串的最小长度。
              再次声明：本功能仅限于开始一个新任务时使用，运行途中不得修改。
            </p>
            <p>
              <a href="#index">返回目录</a>
            </p>
          </div>
        </div>

        <div className="form-group">
          <div className="col">
            <h3 id="threading">线程数使用说明</h3>
            <p>
              关于线程数的说明：目前本软件最大支持512个线程。
              如果您的电脑配置很高，则使用多个线程才能发挥出电脑的最大性能；
              如果您的电脑配置不是很高，那么仅用3个线程就可以发挥最高性能。
              软件会智能选择最佳线程数，建议保持默认值。 如需详细信息，请参阅
              <a
                href="https://baike.baidu.com/item/%E5%A4%9A%E7%BA%BF%E7%A8%8B"
                target="_blank"
                rel="noopener noreferrer"
                title="多线程"
              >
                多线程详解
              </a>
              。
            </p>
            <p>
              <a href="#index">返回目录</a>
            </p>
          </div>
        </div>

        <div className="form-group">
          <div className="col">
            <h3 id="others">其他使用说明</h3>
            <p>
              目前RAR支持的最大密码长度为：127，当软件运行密码长度超过这个数值后，软件将自动停止。
            </p>
            <p>
              关于“重置”的说明：执行后，软件将停止正在进行的RAR任务，并且恢复到初始状态。使用户可以在不退出软件的情况下中断一个RAR任务，开始另一个RAR的密码恢复。
            </p>
            <p>
              本软件通过注册表来保存部分用户设置，这里将本软件所使用的注册表项向您公开：HKEY_CURRENT_USER\Software\Alien
              Laboratory\RAR Password
              Generator\，以便您通过注册表编辑器管理它们。
            </p>
            <p>
              如果您想使用自己提供的
              UnRAR.dll，请在：HKEY_CURRENT_USER\Software\Alien Laboratory\RAR
              Password Generator\ 下创建 UseMyOwnUnrarDLL（DWORD
              类型），并赋值为 1 ，然后再将您的 UnRAR.dll
              覆盖到本软件的保存目录下。
            </p>
            <p>
              <a href="#index">返回目录</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
