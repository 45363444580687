import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  //UncontrolledDropdown,
  //DropdownToggle,
  //DropdownMenu,
  //DropdownItem,
  NavbarText,
} from "reactstrap";
import { connect } from "react-redux";

export class ConnectedNavMenu extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapsed: true,
    };
  }

  toggle() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    return (
      <div>
        <Navbar color="success" dark expand="md">
          <NavbarBrand tag={Link} to="/">
            Alienlab
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={!this.state.collapsed} navbar>
            <Nav className="me-auto" navbar>
              <NavItem>
                <NavLink tag={Link} to="/download/">
                  下载
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/help/">
                  帮助
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/about/">
                  关于
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink tag={Link} to="/second/">
                  Second
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/third/">
                  Third
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Options
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem>Option 1</DropdownItem>
                  <DropdownItem>Option 2</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>Reset</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
            </Nav>
            <NavbarText>
              {this.props.loginInfo?.loading === true
                ? "Loading..."
                : `Welcome ${this.props.loginInfo?.user.username}`}
            </NavbarText>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { loginInfo: state.userInfo };
};

const NavMenu = connect(mapStateToProps)(ConnectedNavMenu);

export default NavMenu;
