import { Card, CardText, CardTitle, Button, Badge } from "reactstrap";
import moment from 'moment';

export default function DownloadItem(props) {
    const item = props.data
    const updatedTime = moment(item.updateTime).format('L')
    const imagePath = process.env.PUBLIC_URL
    return (
        <Card body style={{ marginTop: "3px", marginBottom: "3px" }}>
            <CardTitle tag="h5">
                {item.title} <Badge color={item.badgeColor}>{item.badgeText}</Badge>
            </CardTitle>
            <CardText>{item.text1}</CardText>
            <CardText>
                {item.text2}
            </CardText>
            {
                item.promptText ? <CardText>
                    <small className="text-muted">{item.promptText}</small>
                </CardText> : null
            }
            <CardText>
                <small className="text-muted">Last updated on {updatedTime}</small>
            </CardText>
            {
                item.imageSrc ? <img
                    src={imagePath + item.imageSrc}
                    className="img-fluid"
                    alt="软件预览"
                    style={{
                        maxWidth: "886px",
                        margin: "3px",
                    }}
                ></img> : null
            }
            {
                item.downloadUrl ? <Button
                    color="primary"
                    href={item.downloadUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    下载
                </Button> : null
            }
        </Card>
    );
}
