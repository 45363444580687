class TradeModel {
    constructor(price1=100, qty1=10, price2=108, qty2=8, fee=5) {
        this.price1 = price1;
        this.qty1 = qty1;
        this.price2 = price2;
        this.qty2 = qty2;
        this.fee = fee;
    }
}

export default TradeModel;