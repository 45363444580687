import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import DownloadItem from "./DownloadItem";

export default class Download extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: []
        };
    }

    componentDidMount() {
        fetch(`/api/download/filelist`)
            .then(res => res.json())
            .then((result) => {
                this.setState({
                    fileList: result
                });
            }, (error) => {
                console.error(error)
            })
    }

    render() {
        const listItems = this.state.fileList.map(item =>
            <Col key={item.id} sm="6">
                <DownloadItem data={item} />
            </Col>
        );
        return (
            <div style={{ marginTop: "3px", marginBottom: "3px" }}>
                <Row>
                    {listItems}
                </Row>
            </div>
        );
    }
}
