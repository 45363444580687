/* eslint-disable no-unused-vars */
export default class userService {
  getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  };

  login = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ data: { result: 0, username: "Guest" } });
        // if login failed then reject
        // reject({ error: { result: 1 } });
      }, 1000);
    });
  };
}
