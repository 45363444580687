import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";

export default class Reader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: "",
      output: "",
      loading: false,
    };
  }

  componentDidMount = () => {
    var queryString = window.location.search;
    if (queryString) {
      const params = new URLSearchParams(queryString);
      const value = params.get("key");
      this.setState({
        input: value,
      }, () => {
        this.handleClick();
      });
    }
  }

  handleInputChange = (e) => {
    let text = e.target.value;
    this.setState({
      input: text,
    });
  };

  handleOutputChange = (e) => {
    let text = e.target.value;
    this.setState({
      output: text,
    });
  };

  handleClick = async () => {
    this.setState({
      loading: true,
    });
    const data = { FileName: this.state.input };
    const response = await fetch("/api/File/Load", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    this.setState({
      output: result.content,
      loading: false,
    });
  };

  render() {
    return (
      <div style={{ marginTop: "8px", marginBottom: "8px" }}>
        <Form>
          <FormGroup>
            <InputGroup>
              <InputGroupText>Key</InputGroupText>
              <Input
                type="text"
                name="key"
                id="keyInput"
                placeholder="Input your key here"
                value={this.state.input}
                onChange={this.handleInputChange}
              />
              <Button
                color="success"
                onClick={this.handleClick}
                disabled={this.state.loading}
              >
                {this.state.loading && <Spinner size="sm">Loading...</Spinner>}
                <span> Go</span>
              </Button>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label for="fileOutput">Find your output here</Label>
            <Input
              type="textarea"
              id="fileOutput"
              value={this.state.output}
              onChange={this.handleOutputChange}
              style={{ height: "67dvh" }}
            />
          </FormGroup>
        </Form>
      </div>
    );
  }
}
